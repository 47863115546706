<template>
	<div>
		<div class="filter-container" style="background: #fff;padding: 15px;">
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input clearable style="width:250px" placeholder="员工姓名、手机号" v-model="KeyWords"></el-input>
			</div>
			<div class="filter-item">
				<label class="label">时间筛选: </label>
				<el-date-picker v-model="TimeValue" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
				 range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
				</el-date-picker>
			</div>

			<div class="filter-item">
				<label class="label">门店标签: </label>
				<el-select v-model="ShopTagId" style="margin-right: 10px;">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in tagList" :key="item.Id" :label="item.TagName" :value="item.Id">
					</el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<label class="label">归属门店: </label>
				<el-select filterable v-model="ShopId" style="margin-right: 10px;">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in dropList" :key="item.Id" :label="item.ShopName" :value="item.Id">
					</el-option>
				</el-select>
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="goodsSearch">查询</el-button>
				<el-button type="" style="margin-left:30px;width:90px;" @click="handleExport">导出</el-button>

			</div>
		</div>
		<div style="background: #fff;padding: 15px;">
			<div style="background: #eee;padding: 15px;">锁粉人数：{{Total}}</div>
		</div>
		<div class="table-container" style="background: #fff;padding: 15px;">
			<el-table :data="groupData" style="width: 100%;" v-loading="loading">
				<el-table-column prop="AddTimeStr" label="员工">
					<template slot-scope="scope">
						<div class="note-detail">
							{{scope.row.EmployeeName}}
							<br>
							{{scope.row.Phone}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="ShopName" label="归属门店"></el-table-column>
				<el-table-column label="锁粉客户">
					<template slot-scope="scope">
						<el-tooltip class="item-tooltip" popper-class="lock-Detail-page-tooltip" effect="light" :content="scope.row.CustomerLogo"
						 placement="top-start">
							<div class="content-text">
								{{scope.row.CustomerLogo}}
							</div>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column prop="LockTime" label="锁粉时间"></el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
				</div>
				<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import config from '@/config/index'
	import apiList from '@/api/other'
	import quillConfig from '@/components/quill-config.js'
	import {
		shoptaglist,
		shopdroplist
	} from '@/api/api'
	export default {
		data() {
			return {
				ShopTagId: null,
				tagList: [],
				ShopId: null,
				dropList: [],
				KeyWords: '',
				TimeValue: [],
				groupData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 1,
				searchKey: '',
				loading: false,
				quillOption: quillConfig,
				exportUrl: config.EXPORT_URL,
			}
		},

		beforeMount() {
			if (this.$route.query.employPhone) {
				this.KeyWords = this.$route.query.employPhone
			}
			this.getShoptaglist()
			this.getShopdroplist()
			this.getList()
		},
		created() {},
		methods: {

			async getShopdroplist() {
				try {
					let doorResult = await shopdroplist({
						IsOpenRoleJurisdiction: true
					})
					this.dropList = this.dropList.concat(doorResult.Result)
				} catch (e) {}
			},

			async getShoptaglist() {
				try {
					let doorResult = await shoptaglist({})
					this.tagList = doorResult.Result
				} catch (e) {}
			},

			//获取数据
			async getList() {
				this.loading = true
				try {
					this.TimeValue = (this.TimeValue == null) ? [] : this.TimeValue
					let data = {
						KeyWords: this.KeyWords,
						ShopTagId: this.ShopTagId,
						ShopId: this.ShopId,
						StartTime: this.TimeValue[0],
						EndTime: this.TimeValue[1],
						Skip: (this.currentPage - 1) * this.pageSize,
						Take: this.pageSize,
					}
					let result = await apiList.performanceLockFansList(data)
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];
				} catch (e) {
					console.log('e', e)
				} finally {
					this.loading = false
				}
			},
			goodsSearch() {
				this.currentPage = 1
				this.getList();
			},
			async handleExport() {
				this.loading = true
				try {
					let url = this.exportUrl + '/pc/employeePerformance/lockFansListExport?' +
						'&KeyWords=' + (this.KeyWords ? this.KeyWords : '') +
						'&ShopTagId=' + (this.ShopTagId ? this.ShopTagId : '') +
						'&ShopId=' + (this.ShopId ? this.ShopId : '') +
						'&StartTime=' + (this.TimeValue[0] ? this.TimeValue[0] : '') +
						'&EndTime=' + (this.TimeValue[1] ? this.TimeValue[1] : '')
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
		}
	}
</script>

<style lang="less" scoped>
	.filter-top {
		background: #fff;
		padding: 0 15px;
		width: 100%;
		margin-bottom: 10px;
	}

	.note-detail {
		width: 350px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		/* autoprefixer: ignore next */
		-webkit-box-orient: vertical;
	}
</style>

<style lang="less">
	.lock-Detail-page-tooltip {
		max-width: 530px !important;
	}
</style>
